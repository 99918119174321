import React, {FC, SyntheticEvent} from 'react';
import styles from './VideoPlayerControls.module.css';
import Button from "@mui/material/Button";
import {ChevronLeft, ChevronRight, PlayArrow, Stop} from "@mui/icons-material";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {activeVideoActions} from "../../reducers/ActiveVideo";
import {Slider} from "@mui/material";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import {projectActions} from "../../reducers/Project";

interface VideoPlayerControlsProps {}
const VideoPlayerControls: FC<VideoPlayerControlsProps> = () => {
    const dispatch = useAppDispatch();
    const isPlaying = useAppSelector(state => state.activeVideoSlice.videoIsPlaying);
    const isFullScreen = useAppSelector(state => state.projectSlice.showFullScreen);
    const durationInFrames = useAppSelector(state => state.activeVideoSlice.videoDurationInFrames);
    const currentFrame = useAppSelector(state => state.activeVideoSlice.currentFrame);

    const onSliderChanged = (event: Event, newValue: number | number[]) => {
        dispatch(activeVideoActions.previewFrame((newValue as number)));
    };

    const onSliderCommit = (event: SyntheticEvent | Event, newValue: number | number[]) => {
        dispatch(activeVideoActions.seekVideoToFrame((newValue as number)));
    };

    const showFullScreen = () => {
        document.documentElement.requestFullscreen();
        dispatch(projectActions.showFullScreen())
    }

    const exitFullScreen = () => {
        document.exitFullscreen();
        dispatch(projectActions.exitFullScreen())
    }

    return (
        <div className={styles.VideoPlayerControls}>
            <div className={styles.SeekBar}>
                <Slider data-id="seek-bar"
                        aria-label="seek"
                        min={1}
                        max={durationInFrames}
                        value={currentFrame}
                        disabled={durationInFrames === 0}
                        onChange={onSliderChanged}
                        onChangeCommitted={onSliderCommit}
                />
            </div>
            <div className={styles.ControlContainer}>
                <div className={styles.SideButton}></div>
                <div className={styles.ControlButtons}>
                    <Button variant="outlined" data-id="frame-back" disabled={isPlaying || currentFrame <= 1}
                            onClick={() => dispatch(activeVideoActions.frameChangeRelative(-1))}><ChevronLeft/></Button>
                    { !isPlaying && <Button data-id="play" variant="outlined" onClick={() => dispatch(activeVideoActions.play())}><PlayArrow/></Button> }
                    { isPlaying && <Button data-id="stop"  variant="outlined" onClick={() => dispatch(activeVideoActions.stop())}><Stop/></Button> }
                    <Button data-id="frame-forward" variant="outlined" disabled={isPlaying || currentFrame >= durationInFrames}
                            onClick={() => dispatch(activeVideoActions.frameChangeRelative(1))}><ChevronRight/></Button>
                </div>
                <div className={styles.SideButton}>
                    { !isFullScreen && <Button data-id="open-full-screen" variant="outlined" onClick={showFullScreen}><FullscreenIcon/></Button>}
                    { isFullScreen && <Button data-id="close-full-screen" variant="outlined" onClick={exitFullScreen}><FullscreenExitIcon/></Button>}
                </div>
            </div>
        </div>
    )
};
export default VideoPlayerControls;
