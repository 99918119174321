import React, {FC} from 'react';
import styles from './ProjectSelector.module.css';
import GiocondaBrandTopBar from "../GiocondaBrandTopBar/GiocondaBrandTopBar";
import {Alert, Card, List, ListItem, ListItemText, Typography} from '@mui/material';
import {useAppDispatch, useAppSelector} from "../../hooks";
import {projectActions} from "../../reducers/Project";
import {ProjectInformation} from "../../reducers/ProjectInformation";
import {Navigate} from "react-router-dom";

interface ProjectSelectorProps {
}

const ProjectSelector: FC<ProjectSelectorProps> = () => {
    const projectList = useAppSelector(s => s.projectSlice.availableProjects);
    const selectedProject = useAppSelector(s => s.projectSlice.currentProject);
    const selectedClip = useAppSelector(s => s.projectSlice.selectedClip);
    const currentUser = useAppSelector(s => s.auth.client);
    const dispatch = useAppDispatch();

    function openProject(project: ProjectInformation) {
        dispatch(projectActions.selectProject(project.s3Key));
    }

    const projectItems = projectList.map(project => (
        <ListItem key={project.name} data-id={`project-${project.name}`} onClick={() => openProject(project)}>
            <ListItemText primary={project.name} secondary={`${project.runs.length} runs`} style={{cursor: 'pointer'}}/>
        </ListItem>
    ));

    return <div className={styles.ProjectSelector}>
        <GiocondaBrandTopBar>
            <h3 className={styles.CustomerName}>
                {currentUser.name}
            </h3>
        </GiocondaBrandTopBar>
        <div className={styles.ListContainer} data-id="project-selection-page">
            <div className={styles.CentralColumn}>
                {projectItems.length === 0 &&
                    <Alert severity="warning">No projects have been assigned to {currentUser.name} yet.</Alert>}
                {projectItems.length > 0 &&
                    <>
                        <Typography variant="h1" style={{fontSize: '3rem'}} gutterBottom className={styles.MainTitle}>
                            Select project
                        </Typography>
                        <Card sx={{width: '100%', maxWidth: 460}}>
                            <List>
                                {projectItems}
                            </List>
                        </Card>
                    </>}
            </div>
        </div>
        {selectedClip && <Navigate to={`/projects/play/${selectedProject?.safeName}`} replace={true}/>}
    </div>
};

export default ProjectSelector;
