import React, {FC, useCallback, useEffect, useMemo, useRef, WheelEvent} from 'react';
import styles from './VideoPlayer.module.css';
import {useAppDispatch, useAppSelector} from "../../hooks";
import {activeVideoActions} from "../../reducers/ActiveVideo";
import {Navigate} from "react-router-dom";
import {assert} from '../../assert';
import LocRecord from "../../data-structures/LocRecord";
import GpsRecord from "../../data-structures/GpsRecord";
import {getFullUrlFromRelative} from "../../GetFullUrlFromRelative";
import {formatFrameUrl} from "../../FormatFrameUrl";
import AssetRecord from "../../data-structures/AssetRecord";
import {projectActions} from "../../reducers/Project";
import { debounce } from 'lodash';

interface VideoPlayerProps {
}

const VideoPlayer: FC<VideoPlayerProps> = () => {
    const shouldPlay = useAppSelector(state => state.activeVideoSlice.desiredPlaybackState);
    const shouldShowFramePreview= useAppSelector(state => state.activeVideoSlice.shouldShowFramePreview);
    const shouldLoadFramePreview = useAppSelector(state => state.activeVideoSlice.shouldLoadFramePreview);
    const shouldShowVideo = useAppSelector(state => state.activeVideoSlice.shouldShowMainVideo);
    const desiredVideoTime = useAppSelector(state => state.activeVideoSlice.desiredVideoTime);
    const previewFrameNumber = useAppSelector(state => state.activeVideoSlice.currentFrameToShowInPreview);
    const currentProject = useAppSelector(state => state.projectSlice.currentProject);
    const currentClipRes = useAppSelector(state => state.projectSlice.selectedResolution);
    const currentClip = useAppSelector(state => state.projectSlice.selectedClip);
    const useHighRes = useAppSelector(state => state.activeVideoSlice.shouldUseHighResPreview);
    const framesToCache = useAppSelector(state => state.activeVideoSlice.framesToCache);
    const currentFrame = useAppSelector(state => state.activeVideoSlice.currentFrame);
    const shouldShowOverlayImage = useAppSelector(state => state.activeVideoSlice.shouldShowGrid);
    const videoElement = useRef<HTMLVideoElement>(null);
    const dispatch = useAppDispatch();
    const locUrl = useAppSelector(state => state.projectSlice.selectedRun?.locFile)
    const gpsUrl = useAppSelector(state => state.projectSlice.selectedRun?.gpsFile)
    const assetsUrl = useAppSelector(state => state.projectSlice.selectedRun?.assetsFile)
    const overlayImageRelativePath = useAppSelector(state => state.projectSlice.selectedRun?.gridImageFile)
    assert(currentProject);

    useEffect(() => {
        if(!videoElement.current)
            return;
        if(desiredVideoTime === null)
            return;

        console.log('Setting video currentTime', desiredVideoTime);
        videoElement.current.currentTime = desiredVideoTime;
    },[videoElement, desiredVideoTime, currentClipRes])

    const resolveUrl = useCallback( (path: string) => {
        return getFullUrlFromRelative(path, currentProject);
    }, [currentProject])

    const scrollFinishedDebounced = useMemo<() => void>(() => {
        return debounce(() => {
            dispatch(activeVideoActions.scrollFinished());
        }, 500)
    }, [dispatch]);

    const loadAndDispatchLoc = useCallback(async () => {
        assert(locUrl, 'invalid loc url');
        dispatch(activeVideoActions.startedLoadingLoc());
        const fullLocUrl = resolveUrl(locUrl);
        console.log('loading loc', locUrl);
        const locResponse = await fetch(fullLocUrl);
        const locJson: LocRecord[] = await locResponse.json();
        dispatch(activeVideoActions.locDataLoaded(locJson));
    }, [locUrl, dispatch, resolveUrl]);

    const loadAndDispatchGps = useCallback(async () => {
        assert(gpsUrl, 'invalid gps url');
        dispatch(activeVideoActions.startedLoadingGps());
        console.log('loading gps', gpsUrl);
        const fullGpsUrl = resolveUrl(gpsUrl);
        const locResponse = await fetch(fullGpsUrl);
        const gpsJson: GpsRecord[] = await locResponse.json();
        dispatch(activeVideoActions.gpsDataLoaded(gpsJson));
    }, [gpsUrl, dispatch, resolveUrl]);

    const loadAndDispatchAssets = useCallback(async () => {
        assert(assetsUrl, 'invalid assets url');
        dispatch(projectActions.startedLoadingAssets());
        console.log('loading assets', assetsUrl);
        const fullGpsUrl = resolveUrl(assetsUrl);
        const assetsResponse = await fetch(fullGpsUrl);
        const assets: AssetRecord[] = await assetsResponse.json();
        dispatch(projectActions.assetsLoaded(assets));
    }, [assetsUrl, dispatch, resolveUrl]);

    useEffect(() => {
        loadAndDispatchLoc().catch(console.error);
    }, [locUrl, loadAndDispatchLoc]);

    useEffect(() => {
        loadAndDispatchGps().catch(console.error);
    }, [gpsUrl, loadAndDispatchGps]);

    useEffect(() => {
        loadAndDispatchAssets().catch(console.error);
    }, [assetsUrl, loadAndDispatchAssets]);

    if(!currentClipRes || !currentClip)
        return <Navigate to="/" replace={true}/>;

    const fullOverlayUrl = overlayImageRelativePath
        ? resolveUrl(overlayImageRelativePath)
        : null;

    const previewFrameHref = useHighRes
        ? currentClip.fullFrameTemplateHref
        : currentClip.previewFrameTemplateHref;

    const currentFramePreviewUrl = formatFrameUrl(previewFrameNumber, previewFrameHref, currentProject);
    const currentVideoUrl = resolveUrl(currentClipRes.href);
    const frameUrlsToCache = framesToCache.map(f => formatFrameUrl(f, currentClip.fullFrameTemplateHref, currentProject));

    if(videoElement.current) {
        if (shouldPlay === true && videoElement.current.paused) {
            console.log('Triggering play');
            videoElement.current?.play().catch(e => {
                // Prevent unhandled promise rejection errors when the video is paused too quickly
            });
        }
        if(shouldPlay === false && !videoElement.current.paused){
            console.log('Triggering pause');
            videoElement.current?.pause();
        }
    }

    let videoLoadedHandler = () => {
        if(!videoElement.current)
            return;
        let duration = videoElement.current.duration;
        if(duration === undefined)
            return;

        dispatch(activeVideoActions.videoLoaded({
            durationInSeconds: duration
        }));
    };

    const timeChanged = () => {
        if(!videoElement.current)
            return;
        let currentTime = videoElement.current.currentTime;
        dispatch(activeVideoActions.videoProgressed(currentTime));
    };

    const previewFrameLoaded = () => {
        dispatch(activeVideoActions.previewFrameLoadComplete());
    }

    const displayNone = { display: "none"};
    const defaultStyle = {};

    function playPause() {
        dispatch(activeVideoActions.playOrStop())
    }

    function onScroll(e: WheelEvent<HTMLDivElement>) {
        let frameChange = e.deltaY > 0 ? -10 : 10;
        let newFrame = currentFrame + frameChange;
        dispatch(activeVideoActions.previewFrame(newFrame));
        scrollFinishedDebounced();
    }

    function videoFinished() {
        dispatch(activeVideoActions.videoEnded());
    }

    return <div className={styles.VideoPlayer} data-id="outer-video-wrapper" onWheel={onScroll}>
        <video ref={videoElement} className={styles.MainPlayer}
               onPlay={() => dispatch(activeVideoActions.videoPlaybackHasStarted())}
               onPause={() => dispatch(activeVideoActions.videoPlaybackHasStopped())}
               onLoad={videoLoadedHandler}
               onDurationChange={videoLoadedHandler}
               onTimeUpdate={timeChanged}
               style={shouldShowVideo ? defaultStyle : displayNone }
               onClick={playPause}
               src={currentVideoUrl}
               autoPlay={false}
               loop={false}
               onEnded={videoFinished}
               data-id="main-video-element">
        </video>
        { shouldLoadFramePreview &&
            <img className={styles.FramePreviewImage}
                 alt="frame-preview"
                 data-preview-res={useHighRes ? 'high' : 'low'}
                 loading="eager"
                 onLoad={previewFrameLoaded}
                 onError={previewFrameLoaded}
                 style={shouldShowFramePreview ? defaultStyle : displayNone }
                 onClick={playPause}
                 src={currentFramePreviewUrl}
                 data-id="preview-image" /> }
        { frameUrlsToCache.map(frameUrl =>
            <img
                 key={frameUrl}
                 alt="frame-cache"
                 loading="eager"
                 style={ displayNone }
                 src={frameUrl} />)
         }
        {shouldShowOverlayImage && fullOverlayUrl != null && <img
            alt="overlay"
            loading="eager"
            data-id="overlay-image"
            className={styles.OverlayImage}
            src={fullOverlayUrl}/>
        }
    </div>
};

export default VideoPlayer;
