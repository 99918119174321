import React, {FC, forwardRef} from 'react';
import styles from './ScreenshotDialog.module.css';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {useAppDispatch, useAppSelector} from "../../hooks";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import DownloadIcon from '@mui/icons-material/Download';
import ScreenshotRenderer from "../ScreenshotRenderer/ScreenshotRenderer";
import {screenshotActions} from "../../reducers/Screenshot";
import {Tooltip} from "@mui/material";
import {activeVideoActions} from "../../reducers/ActiveVideo";

interface ScreenshotDialogProps {
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ScreenshotDialog: FC<ScreenshotDialogProps> = (props) => {
    const currentProject = useAppSelector(state => state.projectSlice.currentProject);
    const currentClip = useAppSelector(state => state.projectSlice.selectedClip);
    const dialogState = useAppSelector(state => state.screenshotSlice.screenshotDialogIsOpen)
    const dispatch = useAppDispatch();

    if(!currentProject || !currentClip){
        return null;
    }

    const handleClose = () => {
        dispatch(screenshotActions.closeDialog());
    };

    const resetAll = () => {
        dispatch(screenshotActions.reset());
    };
    const zoomIn = () => {
        dispatch(screenshotActions.increaseZoom());
    };

    const zoomOut = () => {
        dispatch(screenshotActions.decreaseZoom());
    };

    return (
        <div className={styles.ScreenshotDialog}>
            <Dialog
                fullScreen
                open={dialogState}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            data-id="close-screenshot-dialog"
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            Zoom
                        </Typography>
                        <Tooltip title="Download">
                            <IconButton data-id="download-frame" color="inherit" onClick={() => dispatch(screenshotActions.download())}><DownloadIcon/></IconButton>
                        </Tooltip>
                        <Tooltip title="Previous frame">
                            <IconButton data-id="go-to-previous-frame-zoom" color="inherit" onClick={() => dispatch(activeVideoActions.frameChangeRelative(-1))}><ChevronLeft/></IconButton>
                        </Tooltip>
                        <Tooltip title="Next frame">
                            <IconButton data-id="go-to-next-frame-zoom" color="inherit" onClick={() => dispatch(activeVideoActions.frameChangeRelative(1))}><ChevronRight/></IconButton>
                        </Tooltip>
                        <IconButton data-id="zoom-in-button" color="inherit" onClick={() => zoomIn()}><ZoomInIcon/></IconButton>
                        <IconButton data-id="zoom-out-button" color="inherit" onClick={() => zoomOut()}><ZoomOutIcon/></IconButton>
                        <IconButton data-id="reset-zoom-button" color="inherit" onClick={() => resetAll()}><ZoomOutMapIcon/></IconButton>
                    </Toolbar>
                </AppBar>
                <div className={styles.ScreenshotContainer}>
                    <ScreenshotRenderer/>
                </div>
            </Dialog>
        </div>
    );
}

export default ScreenshotDialog;
