import React, {FC} from 'react';
import {formatFrameUrl} from "../../FormatFrameUrl";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {PanViewer} from "react-image-pan-zoom-rotate";
import {screenshotActions} from "../../reducers/Screenshot";
import {assert} from "../../assert";
import {saveAs} from "file-saver";

interface ScreenshotRendererProps {
}

const ScreenshotRenderer: FC<ScreenshotRendererProps> = (props) => {
    const zoom = useAppSelector(state => state.screenshotSlice.zoom);
    const dx = useAppSelector(state => state.screenshotSlice.dx);
    const dy = useAppSelector(state => state.screenshotSlice.dy);
    const currentRunSafeName = useAppSelector(state => state.projectSlice.selectedRun?.safeName);
    const shouldDownload = useAppSelector(state => state.screenshotSlice.shouldDownloadImage);

    const currentProject = useAppSelector(state => state.projectSlice.currentProject);
    const currentFrameUrlTemplate = useAppSelector(state => state.projectSlice.selectedClip?.zoomFrameTemplateHref);
    const currentFrame = useAppSelector(state => state.activeVideoSlice.currentFrame);
    assert(currentFrameUrlTemplate)
    assert(currentProject)
    const screenshotImage = formatFrameUrl(currentFrame, currentFrameUrlTemplate, currentProject);

    const dispatch = useAppDispatch();

    const download = () => {
        assert(currentProject)
        const url = formatFrameUrl(currentFrame, currentFrameUrlTemplate, currentProject) + '?cache_bust=' + crypto.randomUUID();
        let imageFileName = `${currentRunSafeName}_${currentFrame}.png`;
        (async () => {
            let blob = await fetch(url).then((r) => r.blob());
            saveAs(blob, imageFileName);
        })();
    }

    const onPan = (dx: number, dy: number) => {
        dispatch(screenshotActions.setDx(dx));
        dispatch(screenshotActions.setDy(dy));
        console.log('pan', dx, dy);
    };

    if(shouldDownload)
    {
        download();
        dispatch(screenshotActions.downloadComplete());
    }

    return (
        <PanViewer
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1,
            }}
            zoom={zoom}
            setZoom={z => dispatch(screenshotActions.setZoom(z))}
            pandx={dx}
            pandy={dy}
            onPan={onPan}
            rotation={0}
            key={dx}
        >
            <img data-id="pan-image" src={screenshotImage} alt="screenshot" style={{
                width: '100%'
            }} />
        </PanViewer>
    );
}

export default ScreenshotRenderer;
