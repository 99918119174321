import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { assert } from '../assert';
import {ProjectInformation} from "./ProjectInformation";
import {ProjectState} from "./ProjectState";
import {Resolution} from "./Resolution";
import AssetRecord from "../data-structures/AssetRecord";

const initialState: ProjectState = {
    showFullScreen: false,
    showFullScreenControlBar: false,
    availableProjects: [],
    currentProject: null,
    selectedRun: null,
    selectedClip: null,
    selectedResolution: null,
    assets: [],
    filteredAssets: [],
    filterToIncludeAssetTypes: undefined,
    isFilteringAssets: false,
};

function applyAssetFilters(state: ProjectState) {
    let filters = state.filterToIncludeAssetTypes;
    if (filters === undefined) {
        state.filteredAssets = state.assets;
        state.isFilteringAssets = false;
        return;
    }

    state.filteredAssets = state.assets.filter(a => filters && filters.indexOf(a.group) > -1);
    state.isFilteringAssets = state.filteredAssets.length !== state.assets.length;
}

const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        selectProject(state: ProjectState, action: PayloadAction<string | null>) {
            const project = state.availableProjects.find(p => p.s3Key === action.payload) ?? null;
            if (project === null) {
                state.currentProject = null;
                state.selectedRun = null;
                state.selectedClip = null;
                state.selectedResolution = null;
                return;
            }
            state.currentProject = project;
            state.selectedRun = state.currentProject.runs[0];
            state.selectedClip = state.selectedRun.availableClips[0];
            state.selectedResolution = state.selectedClip.resolutions[0];
        },
        projectsLoaded(state: ProjectState, action: PayloadAction<ProjectInformation[]>) {
            state.availableProjects = action.payload;
        },
        selectRun(state: ProjectState, action: PayloadAction<string>) {
            assert(state.currentProject);
            const foundRun = state.currentProject.runs.find(r => r.safeName === action.payload);
            assert(foundRun);
            state.selectedRun = foundRun;
            state.selectedClip = state.selectedRun.availableClips[0];
            state.selectedResolution = state.selectedClip.resolutions[0];
        },
        selectClip(state: ProjectState, action: PayloadAction<string>) {
            assert(state.selectedRun);
            const foundClip = state.selectedRun.availableClips.find(c => c.name === action.payload);
            assert(foundClip);
            state.selectedClip = foundClip;
            state.selectedResolution = state.selectedClip.resolutions[0];
        },
        selectResolution(state: ProjectState, action: PayloadAction<Resolution>) {
            assert(state.selectedClip);
            const foundResolution = state.selectedClip.resolutions.find(c => c.resolution === action.payload);
            assert(foundResolution);
            state.selectedResolution = foundResolution;
        },
        assetsLoaded: (state, action: PayloadAction<AssetRecord[]>) => {
            state.assets = action.payload;
            applyAssetFilters(state);
        },
        startedLoadingAssets: (state) => {
            state.assets = [];
        },
        setAssetFilter: (state, newFilterTypesAction: PayloadAction<string[]>) => {
            state.filterToIncludeAssetTypes = newFilterTypesAction.payload;
            const hasSelectedAllAssetTypes = state.assets
                .filter(a => newFilterTypesAction.payload.indexOf(a.group) === -1)
                .length === 0;
            if(hasSelectedAllAssetTypes)
                state.filterToIncludeAssetTypes = undefined;
            applyAssetFilters(state);
        },
        showFullScreen(state) {
            state.showFullScreen = true;
            state.showFullScreenControlBar = true;
        },
        exitFullScreen(state) {
            state.showFullScreen = false;
            state.showFullScreenControlBar = false;
        },
        mouseInactive(state) {
            state.showFullScreenControlBar = false;
        },
        mouseActivity(state) {
            state.showFullScreenControlBar = true;
        }
    }
});

const projectActions = projectSlice.actions;
const projectReducer = projectSlice.reducer;
export {
    projectActions
}

export default projectReducer;
