import {createSlice, Draft, original, PayloadAction} from '@reduxjs/toolkit'
import LocRecord from "../data-structures/LocRecord";
import GpsRecord from "../data-structures/GpsRecord";

const VIDEO_FRAMES_PER_SECOND = 25;

enum PlayerState {
    Stopped = 'stopped',
    FrameSeeking = 'frame-seeking',
    WaitingForPreviewFrameToLoad = 'waiting-for-preview-frame-load',
    WaitingForVideoToSeek = 'waiting-for-video-seek',
    WaitingForIdenticalVideoLoad = 'waiting-for-res-change-to-resume',
    PlayRequested = 'play-requested'
}

const initialState = {
    currentFrame: 1,
    currentFrameToShowInPreview: 1,
    desiredPlaybackState: false,
    videoIsPlaying: false,
    videoDurationInFrames: 0,
    shouldShowFramePreview: false,
    currentVideoUrl: '',
    shouldShowMainVideo: true,
    shouldLoadFramePreview: false,
    desiredVideoTime: 0,
    playerState: PlayerState.Stopped,
    framesToCache: Array<number>(),
    shouldShowGrid: false,
    shouldUseHighResPreview: false,
    nearestLoc: undefined,
    nearestGps: undefined,
    frameToGpsFast: new Map<number, GpsRecord>(),
    frameToLocFast: new Map<number, LocRecord>(),
    currentTimeStamp: "00:00:00.00",
    videoIsBuffering: false,
    lastRelativeMove: undefined,
    nextFrameToShowInPreview: 0,
    metreToLocFast: new Map<number, LocRecord>(),
    assets: []
} as ActiveVideoState

export interface ActiveVideoState {
    framesToCache: number[];
    shouldUseHighResPreview: boolean;
    currentTimeStamp: string;
    // The time that the video element should be seeked to in seconds
    desiredVideoTime: number | null;
    videoIsPlaying: boolean;
    videoIsBuffering: boolean;
    currentFrame: number
    lastRelativeMove?: number;
    // If the video should be playing as a result of the user request
    desiredPlaybackState: boolean | null;
    currentVideoUrl: string
    videoDurationInFrames: number;

    // If the system should begin loading the preview frame in the background
    // (img element present but not visible in dom, with eager load)
    shouldLoadFramePreview: boolean;
    // Should overlay video with img element for small res fast frame
    shouldShowFramePreview: boolean;
    shouldShowMainVideo: boolean;
    shouldShowGrid: boolean;

    currentFrameToShowInPreview: number
    nextFrameToShowInPreview: number;

    // Location data
    nearestLoc?: LocRecord;
    nearestGps?: GpsRecord;

    frameToLocFast: Map<number, LocRecord>;
    frameToGpsFast: Map<number, GpsRecord>;

    playerState: PlayerState;
    metreToLocFast: Map<number, LocRecord>;
}

interface LoadedVideoInformation {
    durationInSeconds: number;
}

function seekVideoToFrame(state: Draft<ActiveVideoState>, frame: number) {
    state.playerState = PlayerState.WaitingForVideoToSeek;
    state.currentFrame = frame;
    const currentTime = state.currentFrame / VIDEO_FRAMES_PER_SECOND;
    state.desiredVideoTime = currentTime;
    state.currentTimeStamp = secondsToTimeStamp(currentTime);
}

function getPrecacheFrames(currentFrame: number, lastFrame: number) {
    if(currentFrame === 1)
        return [2];
    if(currentFrame === lastFrame)
        return [lastFrame-1];

    return [currentFrame - 1, currentFrame + 1];
}

function getSafeFrame(frame: number, videoDurationInFrames: number): number {
    return Math.max(1, Math.min(frame, videoDurationInFrames));
}

function goDirectToFrame(frame: number, state: Draft<ActiveVideoState>) {
    const frameToShow = getSafeFrame(frame, state.videoDurationInFrames);
    state.lastRelativeMove = undefined;
    state.currentFrameToShowInPreview = frameToShow;
    state.nextFrameToShowInPreview = frameToShow;
    state.currentFrame = frameToShow;
    state.shouldUseHighResPreview = true;
    state.shouldLoadFramePreview = true;
    state.playerState = PlayerState.WaitingForPreviewFrameToLoad;
    populateLocAndGps(state);
}

function hasVideoEnded(state: Draft<ActiveVideoState>) {
    return state.currentFrame >= state.videoDurationInFrames;
}

function restartVideoIfEnded(state: Draft<ActiveVideoState>) {
    let isAtEndOfVideo = hasVideoEnded(state);
    if (isAtEndOfVideo) {
        state.desiredVideoTime = 0;
    }
    return isAtEndOfVideo;
}

const activeVideoSlice = createSlice({
    name: 'activeVideo',
    initialState,
    reducers: {
        previewFrame: (state, action: PayloadAction<number>) => {
            if (state.playerState === PlayerState.FrameSeeking &&
                state.currentFrameToShowInPreview === action.payload) {
                return;
            }
            const targetFrame = getSafeFrame(action.payload, state.videoDurationInFrames);

            state.shouldUseHighResPreview = false;
            state.shouldLoadFramePreview = true;
            state.currentFrame = targetFrame;
            state.nextFrameToShowInPreview = targetFrame;
            state.desiredPlaybackState = false;
            state.currentTimeStamp = secondsToTimeStamp(state.currentFrame/VIDEO_FRAMES_PER_SECOND);

            populateLocAndGps(state);

            if (state.playerState === PlayerState.WaitingForPreviewFrameToLoad) {
                return;
            }
            state.playerState = PlayerState.WaitingForPreviewFrameToLoad;
            state.currentFrameToShowInPreview = action.payload;
        },
        seekVideoToFrame: (state, action: PayloadAction<number>) => {
            seekVideoToFrame(state, action.payload);
        },
        frameChangeRelative: (state, action: PayloadAction<number>) => {
            state.shouldUseHighResPreview = true;
            state.playerState = PlayerState.WaitingForPreviewFrameToLoad;
            state.shouldLoadFramePreview = true;
            let incrementedFrame = state.currentFrame + action.payload;
            const newFrame = Math.max(1, Math.min(state.videoDurationInFrames+1, incrementedFrame));

            state.currentFrameToShowInPreview = newFrame
            state.nextFrameToShowInPreview = newFrame;
            state.currentFrame = newFrame;
            state.lastRelativeMove = action.payload;
            state.currentTimeStamp = secondsToTimeStamp((newFrame-1)/VIDEO_FRAMES_PER_SECOND);
            populateLocAndGps(state);
        },
        play: (state) => {
            console.log('Frame', state.currentFrame, state.videoDurationInFrames);
            restartVideoIfEnded(state);
            let isAtEndOfVideo = hasVideoEnded(state);
            if(!isAtEndOfVideo && state.playerState === PlayerState.FrameSeeking){
                seekVideoToFrame(state, state.currentFrameToShowInPreview);
            }
            state.desiredPlaybackState = true;
            state.videoIsPlaying = true;
            state.playerState = PlayerState.PlayRequested;
        },
        stop: (state) => {
            state.desiredPlaybackState = false;
            state.videoIsPlaying = false;
            goDirectToFrame(state.currentFrame, state);
        },
        videoEnded: (state) => {
            state.desiredPlaybackState = false;
            state.videoIsPlaying = false;
            goDirectToFrame(state.videoDurationInFrames, state);
        },
        videoPlaybackHasStarted: (state) => {
            state.videoIsPlaying = true;
        },
        videoPlaybackHasStopped: (state) => {
            state.videoIsPlaying = false;
        },
        videoProgressed: (state, action: PayloadAction<number>) => {
            console.log('Video progressed', action.payload)
            if (state.playerState === PlayerState.WaitingForIdenticalVideoLoad) {
                return;
            }
            let waitingForSeek = state.playerState === PlayerState.WaitingForVideoToSeek;
            if (waitingForSeek) {
                state.shouldShowFramePreview = true;
                state.shouldLoadFramePreview = true;
                state.shouldShowMainVideo = false;
                state.shouldUseHighResPreview = true;
                state.playerState = PlayerState.Stopped;
            }
            if(state.playerState === PlayerState.PlayRequested){
                state.shouldShowMainVideo = true;
                state.shouldLoadFramePreview = false;
                state.shouldShowFramePreview = false;
            }

            if(!waitingForSeek){
                state.currentFrame = Math.round(action.payload * VIDEO_FRAMES_PER_SECOND + 1);
                state.videoIsBuffering = false;
                state.currentTimeStamp = secondsToTimeStamp(action.payload);
            }
            if(state.playerState === PlayerState.WaitingForPreviewFrameToLoad){
                goDirectToFrame(state.currentFrame, state);
            }

            if(state.desiredPlaybackState === true)
                state.desiredPlaybackState = null;
            state.desiredVideoTime = null;
            populateLocAndGps(state);
        },
        videoBuffering: (state) => {
            state.videoIsBuffering = true;
        },
        videoLoaded: (state, action: PayloadAction<LoadedVideoInformation>) => {
            state.videoDurationInFrames = action.payload.durationInSeconds * VIDEO_FRAMES_PER_SECOND;
            state.desiredPlaybackState = false;
            state.videoIsPlaying = false;

            if (state.playerState !== PlayerState.WaitingForPreviewFrameToLoad
                && state.playerState !== PlayerState.WaitingForVideoToSeek) {
                state.desiredVideoTime = 0;
            }

            if (state.playerState === PlayerState.WaitingForIdenticalVideoLoad) {
                state.desiredVideoTime = (state.currentFrame-1) / VIDEO_FRAMES_PER_SECOND;
                state.playerState = PlayerState.Stopped;
            }
        },
        previewFrameLoadComplete: (state) => {
            if (state.playerState !== PlayerState.WaitingForPreviewFrameToLoad) {
                return;
            }
            state.playerState = PlayerState.FrameSeeking;
            if (state.nextFrameToShowInPreview &&
                state.currentFrameToShowInPreview !== state.nextFrameToShowInPreview) {
                state.playerState = PlayerState.WaitingForPreviewFrameToLoad;
            }
            if(state.nextFrameToShowInPreview)
                state.currentFrameToShowInPreview = state.nextFrameToShowInPreview;
            state.shouldShowFramePreview = true;
            state.shouldShowMainVideo = false;
            let lastRelativeMoveWasByOneFrame =
                !state.lastRelativeMove
                || Math.abs(state.lastRelativeMove) === 1;
            if(state.shouldUseHighResPreview && lastRelativeMoveWasByOneFrame)
                state.framesToCache = getPrecacheFrames(state.currentFrame, state.videoDurationInFrames)
        },
        seekCurrentTimeOnNextVideoLoad: (state) => {
            state.playerState = PlayerState.WaitingForIdenticalVideoLoad;
        },
        locDataLoaded: (state, action: PayloadAction<LocRecord[]>) => {
            const fastMetreMap = new Map();
            action.payload.forEach(l => fastMetreMap.set(l.metre, l));
            state.metreToLocFast = fastMetreMap;
            state.frameToLocFast = buildFastLookup(action.payload);
            state.nearestLoc = state.frameToLocFast.get(state.currentFrame);
        },
        playOrStop: (state) => {
            if(state.playerState === PlayerState.FrameSeeking){
                seekVideoToFrame(state, state.currentFrameToShowInPreview);
            }
            restartVideoIfEnded(state);
            state.playerState = PlayerState.PlayRequested;
            state.desiredPlaybackState = !state.videoIsPlaying;
            state.videoIsPlaying = state.desiredPlaybackState;
        },
        gpsDataLoaded: (state, action: PayloadAction<GpsRecord[]>) => {
            state.frameToGpsFast = buildFastLookup(action.payload);
            state.nearestGps = state.frameToGpsFast.get(state.currentFrame);
        },
        startedLoadingLoc: (state) => {
            state.frameToLocFast = new Map();
            state.nearestLoc = undefined;
        },
        startedLoadingGps: (state) => {
            state.frameToGpsFast = new Map();
            state.nearestGps = undefined;
        },
        goDirectToFrame: (state, action: PayloadAction<number>) => {
            goDirectToFrame(action.payload, state);
        },
        goDirectToMeterage: (state, action: PayloadAction<number>) => {
            const desiredMeterage = action.payload;
            const loc = original(state.metreToLocFast)?.get(desiredMeterage);
            if(loc)
                goDirectToFrame(loc.frame, state);
        },
        toggleGrid: (state) => {
            state.shouldShowGrid = !state.shouldShowGrid;
        },
        scrollFinished(state) {
            state.shouldUseHighResPreview = true;
        }
    }
});


function populateLocAndGps(state: ActiveVideoState) {
    if (state.frameToLocFast) {
        state.nearestLoc = original(state.frameToLocFast)?.get(state.currentFrame);
    }

    if(state.frameToGpsFast){
        state.nearestGps = original(state.frameToGpsFast)?.get(state.currentFrame);
    }
}


type FrameBasedRecord = {
    frame: number;
}

function buildFastLookup<T extends FrameBasedRecord>(records: T[]): Map<number, T> {
    console.time('fast lookup build');
    const fastLookup = new Map<number, T>();
    const rawLocRecords = records.map(r => ({...r, frame: Math.round(r.frame)}));
    const frameToLoc = [];
    let lastLocRecord = rawLocRecords[0];
    for (let i = 1; i < rawLocRecords.length; i++) {
        const currentLoc = rawLocRecords[i];
        for (let interpolatedFrame = lastLocRecord.frame; interpolatedFrame <= currentLoc.frame; interpolatedFrame++) {
            frameToLoc[interpolatedFrame] = getBestMatch(interpolatedFrame, [
                lastLocRecord,
                currentLoc
            ])
        }
        lastLocRecord = currentLoc;
    }

    rawLocRecords.forEach(loc => frameToLoc[loc.frame] = loc);

    frameToLoc.forEach((loc, frame) => {
        fastLookup.set(frame, loc);
    })
    console.timeEnd('fast lookup build');
    return fastLookup;
}

function secondsToTimeStamp(seconds: number) {
    let hours   = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds - (hours * 3600)) / 60);
    seconds = seconds - (hours * 3600) - (minutes * 60);

    let outputHours: string = hours.toLocaleString(undefined, {
        minimumIntegerDigits: 2
    });
    let outputMinutes: string = minutes.toLocaleString(undefined, {
        minimumIntegerDigits: 2
    });
    let outputSeconds: string = seconds.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        minimumIntegerDigits: 2
    });

    return `${outputHours}:${outputMinutes}:${outputSeconds}`;
}

function getBestMatch<T extends FrameBasedRecord>(currentFrame: number, rawLocRecords: T[]): T {
    const currentLoc = rawLocRecords[0];
    const nextLoc = rawLocRecords[1];

    if(!nextLoc)
        return currentLoc;

    if(!currentLoc)
        return nextLoc;

    const distanceToCurrentLoc = Math.abs(currentLoc.frame - currentFrame);
    const distanceToNextLoc = Math.abs(nextLoc.frame - currentFrame);

    return distanceToCurrentLoc < distanceToNextLoc
        ? currentLoc
        : nextLoc;
}

const activeVideoActions = activeVideoSlice.actions;
const activeVideoReducer = activeVideoSlice.reducer;
export {
    activeVideoActions
}

export default activeVideoReducer;
