import {
    Avatar,
    IconButton, InputBase,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Paper
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import React, {FC, useState} from 'react';
import styles from './AssetList.module.css';
import {useAppDispatch, useAppSelector} from "../../hooks";
import {assert} from "../../assert";
import {getFullUrlFromRelative} from "../../GetFullUrlFromRelative";
import {activeVideoActions} from "../../reducers/ActiveVideo";
import ClearIcon from "@mui/icons-material/Clear";
import AssetFilterDialog from '../AssetFilterDialog/AssetFilterDialog';
import {projectActions} from "../../reducers/Project";
interface AssetListProps {}

const AssetList: FC<AssetListProps> = () => {
    const previewFrameHref = useAppSelector(s => s.projectSlice.selectedClip?.previewFrameTemplateHref);
    const allAssets = useAppSelector(s => s.projectSlice.assets);
    const filteredAssets = useAppSelector(s => s.projectSlice.filteredAssets);
    const isFilteringAssets = useAppSelector(s => s.projectSlice.isFilteringAssets);
    const currentProject = useAppSelector(s => s.projectSlice.currentProject);
    const currentFilters = useAppSelector(s => s.projectSlice.filterToIncludeAssetTypes);
    const appDispatch = useAppDispatch();
    const [searchText, setSearchText] = useState('');
    const [filterDialogIsOpen, setFilterDialogIsOpen] = useState(false);
    let assetTypes = Array.from(new Set(allAssets.map(a => a.group)));
    const searchedAssets = filteredAssets.filter(a => !searchText || a.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
    assert(previewFrameHref)
    const getAssetImageUrl = (frame: number) => {
      const relativeFramePath = previewFrameHref.replace('%d', frame.toString());
      assert(currentProject);
      return getFullUrlFromRelative(relativeFramePath, currentProject)
    };

    function navigateTo(value: number) {
        appDispatch(activeVideoActions.goDirectToFrame(value));
    }

    function clearSearch() {
        setSearchText('');
    }

    function modalComplete(filteredItemTypes: string[]) {
        appDispatch(projectActions.setAssetFilter(filteredItemTypes));
        setFilterDialogIsOpen(false);
    }

    return <Paper className={styles.SearchBoxAndList} data-id="asset-list-and-search">
        <Paper style={{marginBottom:10}} sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
            <InputBase
                data-id="search-assets"
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search..."
                inputProps={{ 'aria-label': 'search google maps' }}
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
            />
            { searchText &&
                <IconButton  onClick={() => clearSearch()} type="button" sx={{ p: '10px' }} data-id="clear-asset-search-button" aria-label="clear search">
                <ClearIcon />
            </IconButton>}
            { !searchText &&
                <IconButton data-id="show-filter-dialog" color={isFilteringAssets ? 'warning': 'default'}
                            onClick={() => setFilterDialogIsOpen(true)} type="button" sx={{ p: '10px' }} aria-label="filters">
                <FilterAltIcon />
            </IconButton>}
        </Paper>
        <div className={styles.AssetList}>
            {
                searchedAssets.length === 0 &&
                <div className={styles.NoAssetsMessage} data-id="no-assets-found-message">No assets found.</div>
            }

            { searchedAssets.length > 0 &&
                <List dense sx={{width: '100%', maxWidth: 460, bgcolor: 'background.paper'}}>
                    {searchedAssets.map((asset, index) => {
                        return (
                            <ListItem
                                data-id={`asset-${index}`}
                                key={asset.frame}
                                disablePadding
                                onClick={() => navigateTo(asset.frame)}
                            >
                                <ListItemButton>
                                    <ListItemAvatar>
                                        <Avatar sx={{width: 48, height: 27}}
                                                variant="square"
                                                alt={`Asset ${asset}`}
                                                src={getAssetImageUrl(asset.frame)}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText primary={asset.name} secondary={asset.group} data-id="asset-text"/>
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            }
            <AssetFilterDialog
                isOpen={filterDialogIsOpen}
                assetTypes={assetTypes}
                modalComplete={filteredItemTypes => modalComplete(filteredItemTypes)}
                currentFilters={currentFilters || assetTypes}></AssetFilterDialog>
        </div>
    </Paper>
};

export default AssetList;
