import React, {FC, LegacyRef, useEffect, useMemo, useState} from 'react';
import styles from './ProjectPlayer.module.css';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select, SelectChangeEvent, Tooltip
} from "@mui/material";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import VideoPlayerControls from "../VideoPlayerControls/VideoPlayerControls";
import FrameStatsCard from "../FrameStatsCard/FrameStatsCard";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {projectActions} from "../../reducers/Project";
import GiocondaBrandTopBar from "../GiocondaBrandTopBar/GiocondaBrandTopBar";
import {Navigate, useParams} from "react-router-dom";
import {Resolution} from "../../reducers/Resolution";
import {assert} from "../../assert";
import {activeVideoActions} from "../../reducers/ActiveVideo";
import ToolsCard from "../ToolsCard/ToolsCard";
import AssetList from "../AssetList/AssetList";
import { ResizableBox } from 'react-resizable';
import {ChevronRight} from "@mui/icons-material";
import ScreenshotDialog from "../ScreenshotDialog/ScreenshotDialog";
import {debounce} from "lodash";

interface DashboardProps {
}

const ProjectPlayer: FC<DashboardProps> = () => {
    const dispatch = useAppDispatch();
    const isBuffering = useAppSelector(state => state.activeVideoSlice.videoIsBuffering);
    const isFullScreen = useAppSelector(state => state.projectSlice.showFullScreen);
    const showFullScreenControls = useAppSelector(state => state.projectSlice.showFullScreenControlBar);
    const hasAssets = useAppSelector(state => state.projectSlice.assets.length > 0);
    const currentProject = useAppSelector(state => state.projectSlice.currentProject)
    const currentRun = useAppSelector(state => state.projectSlice.selectedRun)
    const currentClip = useAppSelector(state => state.projectSlice.selectedClip)
    const currentResolution = useAppSelector(state => state.projectSlice.selectedResolution)
    const availableProjects = useAppSelector(state => state.projectSlice.availableProjects)
    const {projectSafeName} = useParams();
    const [showAssetList, setShowAssetList] = useState(false)

    useEffect(() => {
        function onFullscreenChange() {
            const isFullScreen = Boolean(document.fullscreenElement);
            let actionToMatchFullScreenState =
                isFullScreen
                    ? projectActions.showFullScreen()
                    : projectActions.exitFullScreen();
            dispatch(actionToMatchFullScreenState);
        }

        document.addEventListener('fullscreenchange', onFullscreenChange);
        return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
    }, [dispatch]);

    useEffect(() => {
        let playPause = (keyEvent: KeyboardEvent) => {
            console.log(keyEvent.target);
            if(keyEvent.target !== window.document.body)
                return;
            if(keyEvent.key === ' ')
                dispatch(activeVideoActions.playOrStop());
        };
        document.addEventListener('keyup', playPause);
        return () => document.removeEventListener('keyup', playPause);
    }, [dispatch]);

    const mouseInactivityDebounce = useMemo<() => void>(() => {
        return debounce(() => {
            dispatch(projectActions.mouseInactive());
        }, 2000);
    }, [dispatch]);

    assert(projectSafeName, 'project safe name param missing');

    if(!currentProject)
        return <Navigate to={`/projects/play/${projectSafeName}/init`}/>;

    if(!currentResolution)
        return <Navigate to="/" replace={true}/>;

    assert(currentClip, 'no current clip');
    assert(currentRun, 'no current run');

    const projectOptions = availableProjects.map((p) =>
        (<MenuItem key={p.name} value={p.s3Key}>{p.name}</MenuItem>))

    function getProjectOptions() {
        if (currentProject === null) {
            return {
                runOptions: [],
                clipOptions: [],
                resolutionOptions: []
            }
        }

        const runOptions = currentProject?.runs
            .map((p) => (<MenuItem key={p.safeName} value={p.safeName}>{p.name}</MenuItem>))
        const clipOptions = currentRun?.availableClips
            .map((p) => (<MenuItem key={p.name} value={p.name}>{p.name}</MenuItem>))
        const resolutionOptions = currentClip?.resolutions.map((p) => (
            <MenuItem key={p.resolution} value={p.resolution}>{p.resolution}</MenuItem>))

        return {
            runOptions,
            clipOptions,
            resolutionOptions
        }
    }

    const {runOptions, clipOptions, resolutionOptions} = getProjectOptions();

    const projectChanged = (e: SelectChangeEvent<string>) => {
        let selectedProjectName = e.target.value;
        dispatch(projectActions.selectProject(selectedProjectName))
    }

    function runChanged(e: SelectChangeEvent<string>) {
        dispatch(projectActions.selectRun(e.target.value));
    }

    function clipChanged(e: SelectChangeEvent<string>) {
        dispatch(projectActions.selectClip(e.target.value));
    }

    function resolutionChanged(e: SelectChangeEvent<string>) {
        dispatch(activeVideoActions.seekCurrentTimeOnNextVideoLoad());
        dispatch(projectActions.selectResolution(e.target.value as Resolution));
    }

    function mouseActivity() {
        if(!isFullScreen)
            return;
        dispatch(projectActions.mouseActivity());
        mouseInactivityDebounce();
    }

    function ResizeHandle(ref: LegacyRef<any>) {
        return (
            <div data-id="asset-resize-handle"
                onDoubleClick={() => setShowAssetList(false)}
                ref={ref}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    height: 20,
                    width: 20,
                    margin: 2,
                    userSelect: "none",
                    cursor: "ew-resize"
                }}
            >
                ||
            </div>
        );
    }

    return <div className={styles.Dashboard} onMouseMove={mouseActivity}>
        {!isFullScreen && <GiocondaBrandTopBar>
            <div className={styles.NavControls}>
                <div className={styles.TopDropdown}>
                    <FormControl style={{minWidth: 120}}>
                        <InputLabel id="project-label">Project</InputLabel>
                        <Select
                            data-id="project-selector"
                            labelId="project-label"
                            id="project-selector"
                            value={currentProject?.s3Key ?? ''}
                            label="Project"
                            onChange={projectChanged}>
                            {projectOptions}
                        </Select>
                    </FormControl>
                </div>
                {<div className={styles.ProjectControls}>
                    <div className={styles.TopDropdown}>
                        <FormControl style={{minWidth: 220}}>
                            <InputLabel id="run-label">Run</InputLabel>
                            <Select
                                data-id="run-selector"
                                labelId="run-label"
                                id="run-selector"
                                value={currentRun.safeName}
                                label="Run"
                                onChange={runChanged}>
                                {runOptions}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl style={{minWidth: 80}}>
                            <InputLabel id="clip-label">View</InputLabel>
                            <Select
                                data-id="clip-selector"
                                labelId="clip-label"
                                id="clip-selector"
                                value={currentClip?.name ?? null}
                                label="View"
                                onChange={clipChanged}>
                                {clipOptions}
                            </Select>
                        </FormControl>
                    </div>
                    <span className={styles.Spacer}/>
                    {isBuffering && <span>Buffering</span>}
                    <div className={styles.TopDropdown}>
                        <FormControl>
                            <InputLabel id="quality-label">Quality</InputLabel>
                            <Select
                                data-id="quality-selector"
                                labelId="quality-label"
                                id="quality-selector"
                                value={currentResolution?.resolution ?? null}
                                label="Quality"
                                onChange={resolutionChanged}>
                                {resolutionOptions}
                            </Select>
                        </FormControl>
                    </div>
                </div>}
            </div>
        </GiocondaBrandTopBar>}
        <div className={styles.Middle}>
            {showAssetList && <ResizableBox className={styles.LeftBar} width={300} axis={'x'}
                           resizeHandles={['e']}
                           handle={(h, ref) => ResizeHandle(ref)}
                           minConstraints={[200, 100]} maxConstraints={[1000, 300]}>
                <AssetList/>
            </ResizableBox>}
            {!showAssetList && hasAssets && !isFullScreen &&
                <div className={styles.ResizeOnlyStyle} onClick={() => setShowAssetList(true)}>
                    <Tooltip data-id="show-asset-list-arrow" title="Show asset list">
                        <ChevronRight/>
                    </Tooltip>
                </div>}

            <div className={styles.VideoPlayerContainer} style={isFullScreen ? {padding: 0} : {}}>
                <VideoPlayer/>
            </div>

            {!isFullScreen && <div className={styles.RightBar}>
                <div className={styles.CardContainer}>
                    <FrameStatsCard/>
                </div>
                <div className={styles.CardContainer}>
                    <ToolsCard/>
                </div>
            </div> }
        </div>
        {!isFullScreen && <div className={styles.BottomControlBar}>
            <VideoPlayerControls/>
        </div>}
        { isFullScreen && showFullScreenControls && <div className={styles.FullScreenControlBar}>
            <VideoPlayerControls/>
        </div> }
        <ScreenshotDialog></ScreenshotDialog>
    </div>
};

export default ProjectPlayer;
