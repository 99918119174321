import React, {FC, useState} from 'react';
import styles from './FrameStatsCard.module.css';
import {Card, CardActions, CardContent, Divider, IconButton, Tooltip, Typography} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../hooks";
import InputIcon from '@mui/icons-material/Input';
import InputDialog from "../InputDialog/InputDialog";
import {activeVideoActions} from "../../reducers/ActiveVideo";

interface FrameStatsCardProps {
}

const FrameStatsCard: FC<FrameStatsCardProps> = () => {
    const frame = useAppSelector(state => state.activeVideoSlice.currentFrame);
    const loc = useAppSelector(state => state.activeVideoSlice.nearestLoc);
    const gps = useAppSelector(state => state.activeVideoSlice.nearestGps);
    const time = useAppSelector(state => state.activeVideoSlice.currentTimeStamp);
    const isPlaying = useAppSelector(state => state.activeVideoSlice.videoIsPlaying);
    const dispatch = useAppDispatch();

    const metreFormatted = loc?.metre?.toLocaleString(undefined, {
        useGrouping: false,
        minimumFractionDigits: 1
    });
    const metre = loc?.metre || 0;

    const [goToFrameDialogOpen, setFrameDialogOpen] = useState(false);
    const [goToMetreDialogOpen, setMetreDialogOpen] = useState(false);

    function openGoToFrameDialog() {
        setFrameDialogOpen(true)
    }

    function goToFrame(frame: number) {
        setFrameDialogOpen(false)
        dispatch(activeVideoActions.goDirectToFrame(frame));
    }
    function openGoToMetreDialog() {
        setMetreDialogOpen(true)
    }

    function goToMetre(meterage: number) {
        setMetreDialogOpen(false)
        dispatch(activeVideoActions.goDirectToMeterage(meterage));
    }

    return <div className={styles.FrameStatsCard}>
        <Card>
            <CardContent>
                <Typography color="textSecondary" gutterBottom>
                    Location
                </Typography>
                {loc && <>
                    <div className={styles.DataPair}>
                        <span className={styles.DataLabel}>Meterage</span>
                        <span className={styles.DataValue} data-id="meterage">{metreFormatted}</span>
                        <span>
                            <Tooltip title="Go to meterage">
                                <IconButton disabled={isPlaying}
                                            data-id="go-to-meterage"
                                            size="small" color="primary"
                                            aria-label="Go to specific meterage"
                                            onClick={() => openGoToMetreDialog()}
                                            className={styles.GoToButton}>
                                    <InputIcon/>
                                </IconButton>
                            </Tooltip>
                        </span>
                    </div>
                    <Divider/>
                </>}
                {gps && <>
                    <div className={styles.DataPair}>
                        <span className={styles.DataLabel}>East</span>
                        <span className={styles.DataValue} data-id="east-value">{gps.east}</span>
                    </div>
                    <Divider/>
                    <div className={styles.DataPair}>
                        <span className={styles.DataLabel}>North</span>
                        <span className={styles.DataValue} data-id="north-value">{gps.north}</span>
                    </div>
                    <Divider/>
                    <div className={styles.DataPair}>
                        <span className={styles.DataLabel}>Bearing</span>
                        <span className={styles.DataValue} data-id="bearing-value">{gps.bearing}</span>
                    </div>
                    <Divider/>
                    <div className={styles.DataPair}>
                        <span className={styles.DataLabel}>Height</span>
                        <span className={styles.DataValue} data-id="height-value">{gps.height}</span>
                    </div>
                    <Divider/>
                </>}
                <div className={styles.DataPair}>
                    <span className={styles.DataLabel}>Frame</span>
                    <span className={styles.DataValue} data-id="frame-value">{frame}</span>
                    <span>
                        <Tooltip title="Go to frame">
                            <IconButton disabled={isPlaying}
                                        data-id="go-to-frame"
                                        onClick={() => openGoToFrameDialog()}
                                        size="small" color="primary" aria-label="Go to specific frame"
                                        className={styles.GoToButton}>
                                <InputIcon/>
                            </IconButton>
                        </Tooltip>
                    </span>
                </div>
                <Divider/>
                <div className={styles.DataPair}>
                    <span className={styles.DataLabel}>Time</span>
                    <span className={styles.DataValue} data-id="time-value">{time}</span>
                </div>
                <Divider/>
            </CardContent>
            <CardActions>
            </CardActions>
        </Card>

        <InputDialog isOpen={goToFrameDialogOpen} data-id="go-to-frame-dialog" initialValue={frame} fieldLabel="Frame" prompt={"Go to frame"} onClose={f => goToFrame(f)}/>
        <InputDialog isOpen={goToMetreDialogOpen} data-id="go-to-meterage-dialog" initialValue={metre} fieldLabel="Meterage" prompt={"Go to meterage"} onClose={f => goToMetre(f)}/>
    </div>
};

export default FrameStatsCard;
