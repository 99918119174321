import activeVideoReducer from "./reducers/ActiveVideo";
import projectSliceReducer from "./reducers/Project";
import authReducer from "./reducers/Auth";
import screenshotReducer from "./reducers/Screenshot";

let allReducers = {
    activeVideoSlice: activeVideoReducer,
    projectSlice: projectSliceReducer,
    screenshotSlice: screenshotReducer,
    auth: authReducer
};
export default allReducers
