import React, {FC, useEffect, useState} from "react";
import {Navigate, useSearchParams} from "react-router-dom";
import * as CryptoJS from 'crypto-js';
import {getAppConfig} from "./GetAppConfig";
import {assert} from "../assert";
import {loadProjects} from "../components/ProjectSelector/LoadProjects";
import {authActions} from "../reducers/Auth";
import {projectActions} from "../reducers/Project";
import {GrastGroup} from "../data-structures/GrastGroup";
import {useAppDispatch, useAppSelector} from "../hooks";
import {IOauthToken} from "../../../common-models/dist";
import {IOauthError} from "../../../common-models/dist";
import {GetProjectsResponse} from "../../../common-models/dist";
import GiocondaBrandTopBar from "../components/GiocondaBrandTopBar/GiocondaBrandTopBar";
import {Alert, Box, LinearProgress} from "@mui/material";

export const REDIRECT_RETURN_KEY = 'redirect_to';
export const CODE_VERIFIER_KEY = 'last_code';

export const RedirectToLogin: FC = () => {
    const codeVerifier = crypto.randomUUID();
    const codeChallengeHash = base64URL(CryptoJS.SHA256(codeVerifier));
    const [searchParams] = useSearchParams();
    const redirect = searchParams.get("redirect") as string;
    if (redirect)
        localStorage.setItem(REDIRECT_RETURN_KEY, redirect);
    else
        localStorage.removeItem(REDIRECT_RETURN_KEY);
    localStorage.setItem(CODE_VERIFIER_KEY, codeVerifier);

    const url = generateLoginUrl(codeChallengeHash);
    assert(url);
    window.location.replace(url);
    return null;
}

export const AuthenticateAndRedirect: FC<AuthenticateAndRedirectProps> = (props: AuthenticateAndRedirectProps) => {
    const dispatch = useAppDispatch();
    const isAuthed = useAppSelector(s => s.auth.isAuthed);
    const userHasNoGroup = useAppSelector(s => s.auth.isAuthed && s.auth.client.groupId === '');
    const [authError, setError] = useState(false);

    useEffect(() => {
        (async () => {
            const tokenOrError = await convertCodeToToken(props.code, props.codeVerifier);
            let error = (tokenOrError as IOauthError).error;

            if(error){
                setError(true)
                return;
            }

            console.log('Token', tokenOrError);
            const token = tokenOrError as IOauthToken;
            const authConfig = getAppConfig();
            let userInfoEndpoint = `${authConfig.oauthEndpoint}/userInfo`;
            console.log('Fetching userinfo from endpoint', userInfoEndpoint);
            const userInfo = await fetch(userInfoEndpoint, {
                headers: {
                    authorization: 'Bearer ' + token.access_token
                }
            });

            const userJson = await userInfo.json();
            console.log('User', userJson);
            let getProjectsEndpoint = `${authConfig.apiUrl}/get-projects`;
            console.log('Fetching projects from endpoint', getProjectsEndpoint);
            const projectData = await fetch(getProjectsEndpoint, {
                headers: {
                    authorization: 'Bearer ' + token.access_token
                }
            });

            const getProjectsResponse: GetProjectsResponse = await projectData.json();

            const customer: GrastGroup = {
                groupId: getProjectsResponse.groupId,
                name: getProjectsResponse.groupName,
                projects: getProjectsResponse.projects
            }

            const projects = await loadProjects(customer);
            dispatch(authActions.userAuthed(customer));
            dispatch(projectActions.projectsLoaded(projects));
        })();
    }, [dispatch, props.code, props.codeVerifier]);

    if(!isAuthed && authError) {
        return <div>
            <GiocondaBrandTopBar>
            </GiocondaBrandTopBar>
            <div data-id="auth-failure-message">
                <Alert severity="error">Something went wrong while logging in, please try again. <a href="/" data-id="start-over-link">Start over</a></Alert>
            </div>
        </div>
    }

    if (userHasNoGroup) {
        return <div>
            <GiocondaBrandTopBar>
            </GiocondaBrandTopBar>
            <div data-id="no-group-message">
                <Alert severity="warning">Sorry, you have not been added to a group yet. Please contact Gioconda to be added to a customer group.</Alert>
            </div>
        </div>
    }

    return (isAuthed
        ? <Navigate to={props.newUrl || '/projects'}></Navigate>
        : <div>
            <GiocondaBrandTopBar>
            </GiocondaBrandTopBar>
            <h2 style={{textAlign: 'center'}}>Loading...</h2>
            <Box sx={{ width: '100%' }}>
            <LinearProgress />
            </Box>
        </div>);
};

export const TokenLogin: FC = () => {
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code") as string;
    if (!code)
        return null;
    console.log('code', code);
    const codeVerifier = localStorage.getItem(CODE_VERIFIER_KEY);
    assert(codeVerifier, "No stored verifier code");
    const redirect = localStorage.getItem(REDIRECT_RETURN_KEY) ?? null;

    return (
        <AuthenticateAndRedirect code={code} codeVerifier={codeVerifier} newUrl={redirect}></AuthenticateAndRedirect>);
};

interface AuthenticateAndRedirectProps {
    codeVerifier: string;
    code: string;
    newUrl: string | null;
}

async function convertCodeToToken(code: string, codeVerifier: string): Promise<IOauthToken | IOauthError> {
    const authConfig = getAppConfig();
    let tokenEndpoint = `${authConfig.oauthEndpoint}/token`;
    console.log('Fetching token from endpoint', tokenEndpoint);
    const token = await fetch(tokenEndpoint, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'post',
        body: new URLSearchParams({
            grant_type: 'authorization_code',
            client_id: authConfig.clientId,
            redirect_uri: authConfig.returnUrl,
            code: code,
            code_verifier: codeVerifier
        } as any)
    });
    return await token.json();
}

function generateLoginUrl(codeVerifier: string) {
    const baseUrl = getAppConfig().hostedLoginUrl;
    const authEndpoint = `${baseUrl}/authorize`;
    const clientId = getAppConfig().clientId;
    const redirectUrl = getAppConfig().returnUrl;
    const params =
        new URLSearchParams({
            response_type: 'code',
            client_id: clientId,
            redirect_uri: redirectUrl,
            scope: 'openid',
            code_challenge_method: 'S256',
            code_challenge: codeVerifier
        } as any)

    const paramString = params.toString();
    return `${authEndpoint}?${paramString}`;
}

function base64URL(hash: CryptoJS.lib.WordArray): string {
    return hash.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}
