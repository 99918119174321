import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {GrastGroup} from "../data-structures/GrastGroup";

interface AuthState {
    client: GrastGroup,
    isAuthed: boolean
}

const initialState: AuthState = {
    client: {
        groupId: '',
        name: '',
        projects: []
    },
    isAuthed: false
};

const projectSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        userAuthed: (state, action: PayloadAction<GrastGroup>) => {
            state.client = action.payload;
            state.isAuthed = true;
        }
    }
});

const authActions = projectSlice.actions;
const authReducer = projectSlice.reducer;
export {
    authActions
}

export default authReducer;
