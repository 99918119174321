import React, {FC} from 'react';
import {Navigate, useParams} from "react-router-dom";
import {assert} from "../../assert";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {projectActions} from "../../reducers/Project";
import {activeVideoActions} from "../../reducers/ActiveVideo";

interface DirectPlayProjectProps {}

const DirectPlayProject: FC<DirectPlayProjectProps> = () => {
    const {safeProjectName, frame, runSafeName, clipName} = useParams();
    assert(safeProjectName, 'no safe project name found');

    const project = useAppSelector(s => s.projectSlice.currentProject);
    const projects = useAppSelector(s => s.projectSlice.availableProjects);

    const dispatch = useAppDispatch();
    const matchingProject = projects.find(p => p.safeName === safeProjectName);
    assert(matchingProject, `no matching project for ${safeProjectName}`);
    dispatch(projectActions.selectProject(matchingProject.s3Key));

    if(runSafeName) {
        dispatch(projectActions.selectRun(runSafeName));
    }
    if(clipName) {
        dispatch(projectActions.selectClip(clipName));
    }
    if(frame) {
        dispatch(activeVideoActions.seekVideoToFrame(+frame));
    }

    return <>Loading...
        {<Navigate to={`/projects/play/${project?.safeName}`} replace={true}/>}</>
};

export default DirectPlayProject;
