import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from '@mui/material';
import React, {FC, useState} from 'react';
import styles from './AssetFilterDialog.module.css';

import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
interface AssetFilterDialogProps {
    isOpen: boolean
    assetTypes: string[]
    currentFilters: string[]
    modalComplete(selectedTypes: string[]): void;
}
// TODO: SCROLL BACK IS TRYING TO PRECACHE

const AssetFilterDialog: FC<AssetFilterDialogProps> = (props) => {
    const ListItem = styled('li')(({ theme }) => ({
        margin: theme.spacing(0.5),
    }));

    const sortedAssetTypes = [...props.assetTypes].sort();
    const [selectedTypes, setSelectedTypes] = useState<string[]>(props.currentFilters)

    function selectAssetType(assetType: string) {
        let isHighlighted = selectedTypes.indexOf(assetType) !== -1;
        if(isHighlighted){
            setSelectedTypes(selectedTypes.filter(t => t !== assetType));
            return;
        }
        setSelectedTypes([...selectedTypes, assetType]);
    }

    function closeDialogWithoutSaving() {
        props.modalComplete([...props.currentFilters]);
    }

    function selectAll() {
        setSelectedTypes([...props.assetTypes])
    }

    function selectNone() {
        setSelectedTypes([]);
    }

    function applyClicked() {
        props.modalComplete(selectedTypes);
    }

    return <div className={styles.AssetFilterDialog}>
            <Dialog
                open={props.isOpen}
                onClose={() => closeDialogWithoutSaving()}
                aria-labelledby="asset-filter-dialog-title"
                aria-describedby="asset-filter-dialog-description">
                <DialogTitle id="asset-filter-dialog-title" justifyContent="space-between" display="flex">
                    <span>Asset filters</span>
                    <IconButton onClick={closeDialogWithoutSaving} data-id="close-asset-filters-cross">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Paper
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 0.5,
                            m: 0,
                        }}
                        component="ul">
                        {sortedAssetTypes.map((assetType) => {
                            let isSelected = selectedTypes.indexOf(assetType) !== -1;
                            const assetColour = isSelected ? 'warning' : 'default';

                            return (
                                <ListItem key={assetType}>
                                    <Chip
                                        label={assetType}
                                        data-id={'filter-chip-' + assetType}
                                        onClick={() => selectAssetType(assetType)}
                                        color={assetColour}
                                    />
                                </ListItem>
                            );
                        })}
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button onClick={selectAll} data-id="select-all-asset-types">All</Button>
                    <Button onClick={selectNone} data-id="select-none-asset-types">None</Button>
                    <span style={{'flexGrow': 1}}></span>
                    <Button onClick={applyClicked} data-id="apply-asset-types-filter">Apply</Button>
                </DialogActions>
            </Dialog>
        </div>;
}

export default AssetFilterDialog;
